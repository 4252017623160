import ContactsService from '@/services/whatsapp/contacts.service.js'
import UserInboxTagService from '@/services/whatsapp/user-inbox-tag-service.service.js'
import { Contact } from '@/models/whatsapp/Contact.js'
import { Country } from '@/util/Country.js'
import SelectCountry from '@/components/ContactSource/SelectCountry/SelectCountry.vue'
import CountryService from '@/services/country.service'
import moment from 'moment'
import DateRange from '@/components/DateRange/DateRange.vue'
import { GlobalControl } from '@/classes/stats/GlobalControl.js'
import UserInboxCloseReasonService from '@/services/whatsapp/user-Inbox-close-reason.service.js'
import EventBus from '@/util/EventBus'
import getEnv from '@/util/env'
import { v4 as uuidv4 } from 'uuid'
import AiService from '@/services/ai/ai.service'
import { Agent } from '@/classes/chat/agent'

export default {
  name: 'WhatsappContacts',
  props: {
    routes: {
      type: Object,
      required: true,
    },
    selectedUserMsisdn: {
      type: String,
      required: true,
    },
    showFooterActions: {
      type: Boolean,
      default: true,
      required: false,
    },
    showTableActions: {
      type: Boolean,
      default: true,
      required: false,
    },
    agentFrom: {
      type: Object,
      default: null,
    },
    contactSelection: {
      type: Object,
      default: () => {
        return {
          contactIds: [],
          allItemsSelected: false,
          selectedUserMsisdn: '',
          selectedAction: 1,
          filters: {},
        }
      },
    },
    channels: {
      type: Object,
      default: () => { return { data: [] } },
    },
    selectedChannel: {
      type: Object,
      default: () => {
        return {
          channel: 'whatsapp',
        }
      },
      required: false,
    },
  },
  components: {
    SelectCountry,
    DateRange,
  },
  data () {
    return {
      globalControl: new GlobalControl(),
      timeFilter: { text: this.$t('Toda la historia').toString(), value: 'all' },
      items: [],
      itemsPerPage: 5,
      pageCount: 0,
      page: 1,
      total: 0,
      disabledPagination: true,
      options: {},
      searchTerm: '',
      totalItems: 0,
      contact: new Contact(),
      dialogEditContact: false,
      tags: [],
      removeContactDialog: false,
      Country: Country,
      countries: [],
      searchTagText: '',
      confirmContactConsent: true,
      eventUuidContactDialog: '',
      tableAction: null,
      selectedContacts: [],
      user: this.$store.getters['auth/getUser'],
      allItemsSelected: false,
      selectedAction: 1,
      selectedMethod: null,
      loading: false,
      agents: [],
      selectedAgent: null,
      drawerContactFilers: false,
      closedReasons: [],
      filters: {
        statuses: [],
        tags: [],
        closedReasons: [],
        countries: [],
        agents: [],
      },
      dialogFilters: {
        statuses: [],
        tags: [],
        closedReasons: [],
        countries: [],
        agents: [],
      },
      initFiltersHelper: '',
      countriesFromContacts: [],
    }
  },
  computed: {
    methods () {
      return [
        { value: 'set_agent', text: this.$t('Asignar a agente').toString() },
      ]
    },
    actionOptions () {
      return [
        { text: this.$t('Seleccionados').toString(), value: 1 },
        { text: this.$t('No seleccionados').toString(), value: 2 },
        { text: this.$t('Todos (Sin tener en cuenta la paginación)').toString(), value: 3 },
      ]
    },
    pageSelected () {
      return this.selectedContacts.length && this.selectedContacts.length < this.totalItems && !this.allItemsSelected
    },
    countriesFromContactsFiltered () {
      return this.countries.filter((country) => {
          return this.countriesFromContacts.includes(country.value)
        })
    },
    hasFilters () {
      return JSON.stringify(this.filters) !== this.initFiltersHelper
    },
    contactStatuses () {
      return [
        { value: 0, text: this.$t('Cerrado') },
        { value: 1, text: this.$t('Abierto') },
        { value: 2, text: this.$t('Pendiente') },
      ]
    },
    selectedContactIds () {
      return this.selectedContacts.map((contact) => contact.id)
    },
    headers () {
      const headers = [
        {
          value: 'avatar',
          sortable: false,
        },
        {
          text: this.$t('Nombre'),
          value: 'firstName',
        },
        {
          text: this.$t('Teléfono'),
          value: 'msisdnClient',
        },
        {
          text: this.$t('Estado'),
          value: 'statusName',
        },
        {
          text: this.$t('Razón de cierre'),
          value: 'lastClosedReasonText',
        },
        {
          text: this.$t('Última actividad'),
          value: 'lastInteractionAt',
        },
        {
          text: this.$t('Agente'),
          value: 'agentId',
          align: 'center',
          cellClass: 'pb-1',
          sortable: false,
        },
        {
          text: this.$t('País'),
          value: 'country_iso',
        },
      ]

      if (this.showTableActions) {
        headers.push({
              text: this.$t('Acciones'),
              value: 'actions',
              sortable: false,
            })
      }
      return headers
    },
    timeOptions () {
      return [
        { text: this.$t('Hoy'), value: 1, startDate: moment().format('YYYY-MM-DD'), type: 'equal' },
        { text: this.$t('Ayer'), value: 2, startDate: moment().subtract(1, 'days').format('YYYY-MM-DD'), type: 'equal' },
        { text: this.$t('Últimos 7 días'), value: 3, startDate: moment().subtract(7, 'days').format('YYYY-MM-DD'), type: 'between' },
        { text: this.$t('Últimos 30 días'), value: 4, startDate: moment().subtract(30, 'days').format('YYYY-MM-DD'), type: 'between' },
        { text: this.$t('Este mes'), value: 5, startDate: moment().startOf('month').format('YYYY-MM-DD'), type: 'between' },
        { text: this.$t('Mes pasado'), value: 6, startDate: moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'), endDate: moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD'), type: 'between-twice' },
        { text: this.$t('Toda la historia'), value: 'all' },
        { text: this.$t('Personalizado'), value: 'custom' },
      ]
    },
    contactSources () {
      return [
        { value: 1, text: this.$t('MANUAL').toString() },
        { value: 2, text: 'WHATSAPP' },
      ]
    },
    searchTermHelper: {
      get: function () {
        return this.debouncedInput
      },
      set: function (val) {
        this.debouncedInput = val
        if (this.timeout !== null) {
          clearTimeout(this.timeout)
        }
        this.timeout = setTimeout(() => {
          this.searchTerm = val
        }, 700)
      },
    },
    params (nv) {
      return {
        ...this.options,
        query: this.searchTerm,
        time: this.timeFilter,
        selectedUserMsisdn: this.selectedUserMsisdn,
      }
    },
  },
  created () {
    EventBus.$on('whatsapp-inbox-update-contacts', this.getData)
    EventBus.$on('hook:beforeDestroy', () => {
      EventBus.$off('whatsapp-inbox-update-contacts', this.getData)
    })
    EventBus.$on('ChangeCurrentUserMsisdn', this.changeMsisdn)
    EventBus.$on('hook:beforeDestroy', () => {
      EventBus.$off('ChangeCurrentUserMsisdn', this.changeMsisdn)
    })
    this.initFiltersHelper = JSON.stringify(this.filters)

    AiService.getAssistantsSimpleList().then((res) => {
      res.forEach(agent => {
        const agentObject = new Agent(agent)
        this.agents.push(agentObject)
      })
    })

    this.contactSelection.contactIds = []
    this.contactSelection.allItemsSelected = false
    this.$emit('updated-filters', this.filters)
  },
  watch: {
    selectedUserMsisdn: {
      handler () {
        this.selectedUserMsisdnChange()
        this.changeMsisdn(this.contactSelection.selectedUserMsisdn)
      },
    },
    params: {
      handler () {
        this.getData()
      },
      deep: true,
    },
    filters: {
      handler () {
        this.getData()
        this.$emit('updated-filters', this.filters)
      },
      deep: true,
    },
    selectedContacts: {
      handler () {
        this.contactSelection.contactIds = this.selectedContacts.map((contact) => {
          return contact.id
        })
      },
    },
  },
  mounted () {
    this.getAllCountries()
    this.eventUuidContactDialog = 'event-uuid-contact-dialog-' + uuidv4()
    EventBus.$on('ContactEditDialog:updateContact', this.updateContact)
  },
  methods: {

    emitSelectedChannel () {
      this.$emit('change-selected-channel')
    },

    cancelFilters () {
      this.dialogFilters = JSON.parse(this.initFiltersHelper)
      this.filters = JSON.parse(this.initFiltersHelper)
      this.drawerContactFilers = false
    },
    applyFilters () {
      this.options.page = 1
      this.filters = JSON.parse(JSON.stringify(this.dialogFilters))
      this.drawerContactFilers = false
    },
    executeAction () {
      ContactsService.executeAction({
        msisdn: this.selectedUserMsisdn,
        selectedMethod: this.selectedMethod,
        selectedAction: this.selectedAction,
        contactIds: this.selectedContactIds,
        agent: this.selectedAgent,
        filters: this.filters,
      })
          .then((res) => {
            this.getData()
            EventBus.$emit('showAlert', 'success', this.$t('Se han reasignado los contactos'))
      }, () => {
            EventBus.$emit('showAlert', 'danger', this.$t('Ocurrió un error al reasignar los contactos'))
      })
    },
    selectPage () {
      if (this.selectedContacts.length) {
        this.deselectAll()
      } else {
        this.selectedContacts = [...this.items]
      }
    },
    deselectOne () {
      this.allItemsSelected = false
      this.selectedAction = 1
      this.contactSelection.selectedAction = 1
      this.contactSelection.allItemsSelected = false
    },
    selectAll () {
      this.allItemsSelected = true
      this.contactSelection.allItemsSelected = true
      this.selectedAction = 3
      this.contactSelection.selectedAction = 3
    },
    deselectAll () {
      this.allItemsSelected = false
      this.contactSelection.allItemsSelected = false
      this.selectedAction = 1
      this.contactSelection.selectedAction = 1
      this.selectedContacts = []
      this.contactSelection.contactIds = []
    },

    selectedUserMsisdnChange () {
      this.contactSelection.selectedUserMsisdn = this.selectedUserMsisdn
      ContactsService.getContactCountriesByUserMsisdn({ msisdn: this.selectedUserMsisdn }).then((res) => {
        this.countriesFromContacts = res
      })
    },

    resetFilters () {
      this.filters = JSON.parse(this.initFiltersHelper)
    },
    getClosedReasons (msisdn) {
      UserInboxCloseReasonService.getAllReasons({ msisdn: msisdn }).then((closedReasons) => {
        this.closedReasons = closedReasons
      })
    },
    openFilters () {
      this.drawerContactFilers = true
    },
    changeMsisdn (msisdn) {
      this.allTags(msisdn)
      this.getClosedReasons(msisdn)
    },
    exportCsv () {
      const url = getEnv('VUE_APP_API_URL') + 'api/whatsapp/contacts/export-csv' + '?' + this.getUrlParams()
      window.location.href = url
    },

    getUrlParams () {
      const data = this.getParams()
      data.timeFilter = JSON.stringify(data.timeFilter)
      const user = JSON.parse(localStorage.getItem('user'))
      data.token = user.token
      return new URLSearchParams(data).toString()
    },

    copyToClipboard (text) {
      navigator.clipboard.writeText(text)
      EventBus.$emit('showAlert', 'success', this.$t('Texto copiado al portapapeles'))
    },
    fullName (item) {
      return (item.firstName ?? '') + ' ' + (item.lastName ?? '')
    },
    contactLetters (user) {
      return (user.firstName?.charAt(0).toUpperCase() ?? '') + (user.lastName?.charAt(0).toUpperCase() ?? '')
    },
    startsWithEmoji (user) {
      const emojiRegex = /^\p{Extended_Pictographic}/u
      return emojiRegex.test(user.firstName)
    },
    selectDates (dates) {
      this.globalControl.initDate = dates[0]
      this.globalControl.endDate = dates[1]
      this.getData()
    },

    changedTag () {
      this.searchTagText = ''
    },
    getAllCountries () {
      CountryService
        .getAllCountries()
        .then(
          (response) => {
            this.countries = response.map((country) => {
              return {
                value: country.iso_code_2,
                text: `${country.name} +(${country.prefix})`,
              }
            })
          },
          () => {},
        )
    },
    remove () {
      ContactsService.delete(this.contact).then((response) => {
        this.tableAction = 'DELETE'
        this.removeContactDialog = false
        this.getData()
        EventBus.$emit('showAlert', 'success', this.$t('El contacto se ha eliminado correctamente.').toString())
      })
    },
    prepareRemove (item) {
      this.contact.loadData(item)
      this.removeContactDialog = true
    },
    preCreateContact () {
      this.contact = new Contact()
      EventBus.$emit('ContactEditDialog:show', {
        eventUuid: this.eventUuidContactDialog,
        contact: this.contact,
        routes: this.routes,
        selectedUserMsisdn: this.selectedUserMsisdn,
      })
    },
    removeTag (item) {
      this.contact.tags = this.contact.tags.filter(tagId => { return tagId !== item.id })
    },
    allTags (msisdn) {
      UserInboxTagService.allTags({ msisdn }).then((response) => {
        this.tags = response
      })
    },
    closeEditDialog () {
      this.dialogEditContact = false
    },
    updateContact (data) {
      if (data.eventUuid !== this.eventUuidContactDialog) return
      this.dialogEditContact = false
      this.contact = new Contact()
      this.getData()
    },
    prepareEditContact (contact) {
      this.contact.loadData(contact)
      EventBus.$emit('ContactEditDialog:show', {
        eventUuid: this.eventUuidContactDialog,
        contact: this.contact,
        routes: this.routes,
        selectedUserMsisdn: this.selectedUserMsisdn,
      })
    },
    getParams () {
      const timeFilter = this.timeFilter
      if (timeFilter.value === 'custom') {
        timeFilter.startDate = this.globalControl.initDate
        timeFilter.endDate = this.globalControl.endDate
      }

      const page = this.tableAction === 'DELETE' && this.items.length === 1 && this.options.page > 1
        ? this.options.page - 1
        : this.options.page
      this.tableAction = null

      const params = {
        page: page,
        perPage: this.options.itemsPerPage,
        searchTerm: this.searchTerm,
        timeFilter: this.timeFilter,
        selectedUserMsisdn: this.selectedUserMsisdn,
        filters: JSON.stringify(this.filters),
        agentIdFrom: this.agentFrom ? this.agentFrom.id : null,
      }

      for (let i = 0; i < this.options.sortBy.length; i++) {
        params['sortBy[' + i + '][field]'] = this.options.sortBy[i]
        params['sortBy[' + i + '][dir]'] = this.options.sortDesc[i]
          ? 'asc'
          : 'desc'
      }
      return params
    },
    getData () {
      const data = this.getParams()
      this.disabledPagination = true
      ContactsService.getData(data).then((response) => {
        this.items = []
        if (response?.data?.length) {
          this.items = response.data.map(item => {
            return new Contact(item)
          })
        }
        this.itemsPerPage = parseInt(response.per_page)
        this.page = response.current_page
        this.totalItems = response.total
        this.disabledPagination = false
        if (this.allItemsSelected) {
          this.selectedContacts = [...this.items]
        }
      })
    },
  },
}
